<template>
  <div class="home_box box_1" ref="home_box">
    <div class="header">
      <div class="text cursor" @click="goToPage('/bigscreen/menuPage')">巴林右旗农牧业大数据中心</div> 
    </div>

    <div class="content_box full flex_row_around">
      <!-- box1 -->
      <div class="left_box w_25_p flex_column_around">
        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span @click="goToPage('/bigscreen/countyPage')">土地面积</span></div>
          <bing-tu class="chart" :value="data1" />
        </div>

        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span @click="goToPage('/bigscreen/countyFarmal')">种植面积</span></div>
          <kong-xin-bing-tu class="chart" :value="data2" />
        </div>

        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span @click="goToPage('/')">农业总产量</span></div>
          <xian-xing-tu class="chart" :value="data3" />
        </div>

        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span @click="goToPage('/')">病虫害</span></div>
          <zhu-zhuang-tu class="chart" :value="data4" />
        </div>
      </div>

      <!-- box2 -->
      <div class="center_box w_50_p">

        <div class="map_box w_full h_75_p">
          <!-- <ba-lin-you-qi-map class="map"  @changeVillage="changeVillage" /> -->
          <div class="absolute top_40 left_20">
            <span class="fs_16 color_fff">全旗存栏数量：</span>
            <span class="fs_40 font_number num_style">
              <animate-number from="0" :to="728443" duration="3000" />
            </span>
            <span class="fs_16 color_fff ml_5">头</span>
          </div>

          <div class="absolute top_100 left_20">
            <span class="fs_16 color_fff">全旗种植产量：</span>
            <span class="fs_40 font_number num_style">
              <animate-number from="0" :to="728443" duration="3000" />
            </span>
            <span class="fs_16 color_fff ml_5">吨</span>
          </div>
          

          <map-com class="map" />
          <!-- <map-container class="map" /> -->
        </div>

        <div class="chart_box w_full h_25_p flex_row_around">
          <div class="chart_1 w_48_p h_full p_5">
            <div class="title"><span @click="goToPage('/')">智能化统计</span></div>
            <!-- <zhu-zhuang-tu class="chart" :value="data5" /> -->
            <ban-bing-tu class="chart" :value="data5" />
          </div>

          <div class="chart_2 w_48_p h_full p_5">
            <div class="title"><span @click="goToPage('/bigscreen/mashineState巴林右旗机械化统计')">机械化统计</span></div>
            <zhu-zhuang-tu class="chart" :value="data6" />
          </div>
        </div>
      </div>

      <!-- box3 -->
      <div class="right_box w_25_p flex_column_around">
        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span @click="goToPage('/bigscreen/countyPage2')">牲畜存栏</span></div>
          <bing-tu class="chart" :value="data7" />
        </div>

        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span @click="goToPage('/bigscreen/countyAnimal')">养殖户规模</span></div>
          <kong-xin-bing-tu class="chart" :value="data8" />
        </div>

        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span @click="goToPage('/bigscreen/yuZhongJiLu')">育种统计</span></div>
          <xian-xing-tu class="chart" :value="data9" />
        </div>

        <div class="chart_item w_full h_24_p cursor">
          <div class="title"><span>防疫统计</span></div>
          <zhu-zhuang-tu class="chart" :value="data10" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../components/map/baLinYouQiMap.vue'
  import ZhuZhuangTu from '../components/charts/zhu-zhuang-tu.vue';
  import ZhuZhuangTu1 from '../components/charts/zhu-zhuang-tu1.vue';
  import XianXingTu from '../components/charts/xian-xing-tu.vue';
  import BingTu from '../components/charts/bing-tu.vue';
  import KongXinBingTu from '../components/charts/kong-xin-bing-tu.vue';
  import BanBingTu from '../components/charts/ban-bing-tu.vue';
  import LeiDaTu from '../components/charts/lei-da-tu.vue';
  import MapCom from '../components/map/home-map.vue';

  export default {
    name: 'Home',
    components: {
      BaLinYouQiMap,
      ZhuZhuangTu,
      ZhuZhuangTu1,
      XianXingTu,
      BingTu,
      KongXinBingTu,
      BanBingTu,
      LeiDaTu,
      MapCom
    },
    data() {
      return {
        data1: {},
        data2: {},
        data3: {},
        data4: {},
        data5: {},
        data6: {},
        data7: {},
        data8: {},
        data9: {},
        data10: {},
      }
    },
    created() {
    },
    mounted () {
      this.initChart()
    },
    destroyed() {
    },
    methods: {
      // 跳转页面 
      goToPage(val) {
        this.$router.push({
          path: val
        })
      },

      // 初始化图表
      initChart() {
        this.data1 = {
          unit: '万亩',
          data: [
            {"name": "草地", "value": 460, "unit": ''},
            {"name": "林地", "value": 738, "unit": ''},
            {"name": "耕地", "value": 436, "unit": ''},
            {"name": "其他", "value": 352, "unit": ''}
          ]
        }

        this.data2 = {
          unit: '万亩',
          data: [
            {"name": "玉米", "value": 460, "unit": ''},
            {"name": "小麦", "value": 738, "unit": ''},
            {"name": "西瓜", "value": 436, "unit": ''},
            {"name": "甜瓜", "value": 352, "unit": ''}
          ]
        }

        this.data3 = {
          unit: '吨',
          x: ['2019', '2020', '2021', '2022'],
          y: [1845, 3439, 3835, 4343]
        }

        this.data4 = {
          unit: '万亩',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [15, 56, 34, 26, 38]
        }

        this.data5 = {
          unit: '件',
          data: [
            {"name": "开关", "value": 460, "unit": ''},
            {"name": "监控", "value": 738, "unit": ''},
            {"name": "传送带", "value": 436, "unit": ''},
            {"name": "搅拌机", "value": 152, "unit": ''},
            {"name": "清粪机", "value": 232, "unit": ''},
            {"name": "饮水机", "value": 352, "unit": ''}
          ]
        }

        this.data6 = {
          unit: '辆',
          x: ['拖拉机', '收割机', '打草机', '播种机'],
          y: [1845, 3439, 1435, 4343]
        }

        this.data7 = {
          unit: '头',
          data: [
            {"name": "成年牛", "value": 460, "unit": ''},
            {"name": "犊牛", "value": 738, "unit": ''},
            {"name": "育肥牛", "value": 436, "unit": ''},
            {"name": "防疫牛", "value": 352, "unit": ''}
          ]
        }

        this.data8 = {
          unit: '户',
          data: [
            {"name": "<30头", "value": 60, "unit": ''},
            {"name": "30-50头", "value": 738, "unit": ''},
            {"name": "50-100头", "value": 436, "unit": ''},
            {"name": ">100头", "value": 352, "unit": ''}
          ]
        }

        this.data9 = {
          unit: '头',
          yRoate: true,
          x: ['1月', '2月', '3月', '4月'],
          y: [45, 3439, 1835, 343]
        }

        this.data10 = {
          unit: '头',
          yRoate: true,
          x: ['1月', '2月', '3月', '4月'],
          y: [33, 1245, 2334, 1945, 245]
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .home_box {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    /* background-image: url('../assets/images/ba-lin-you-qi/bgc1.png'); */
    /* background-image: url('../assets/images/bgi/img5.png'); */
    background-image: url('../assets/images/bgi/img3.jpg');
    background-size: 100% 100%;

    .header { 
      background-image: url('../assets/images/ba-lin-you-qi/nav-bgc.png');
    }

    .content_box {
      width: calc(100% - 60px);
      height: calc(100% - 80px);
      padding: 0;
      position: absolute;
      top: 53px;
      left: 30px;
      /* padding: 30px; */
      background-image: url('../assets/images/bgi/img1.png');

      /* =================== box1 ==================== */
      .left_box {
        z-index: 99;
        .chart_item {
          /* background-image: url('../assets/images/ba-lin-you-qi/img4.png'); */
          background-image: url('../assets/images/chart-box/img3.png');
          background-size: 100% 100%;
          background-color: rgba(#1b2c57, 0.2);
          /* border: 1px solid #037df7; */

          .title { 
            width: 100%;
            height: 12%;
            position: relative;
           

            span {
              font-weight: bold; 
              color: #fff; 
              cursor: pointer; 
              position: absolute;
              bottom: 0 !important;
              left: 20px;
              padding: 0px 5px;
              box-sizing: border-box;
              letter-spacing: 2px;
            }
          }

          .chart {
            width: 100%;
            height: 88%;
            position: relative;
            padding: 0 !important;
          }
        }
      }

      /* =================== box2 ==================== */
      .center_box {
        position: relative;
        .map_box {
          width: 200%; height: 100%;
          margin-left: -50%;
          z-index: -1;
          .map { width: 100%; height: 75%; }
        }

        .chart_box {
          position: absolute;
          bottom: 0;
          z-index: 99;
          .chart_1, .chart_2 {
            background-color: rgba(#1b2c57, 0.2);
            .title { 
              width: 100%; 
              height: 10%; 
              position: relative;
              z-index: inherit;

              span {
                font-weight: bold; 
                color: #fff; 
                cursor: pointer; 
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
            }

            .chart {
              width: 100%;
              height: 90%;
            }
          } 
          .chart_1 {
            /* background-image: url('../assets/images/ba-lin-you-qi/img6.png'); */
            background-image: url('../assets/images/chart-box/img3.png');
            background-size: 100% 100%;
          }
          .chart_2 {
            /* background-image: url('../assets/images/ba-lin-you-qi/img7.png'); */
            background-image: url('../assets/images/chart-box/img3.png');
            background-size: 100% 100%;
          }
        }
      }
      .right_box {
        z-index: 99;
        .chart_item {
          /*  background-image: url('../assets/images/ba-lin-you-qi/img5.png'); */
          background-image: url('../assets/images/chart-box/img3.png');
          background-size: 100% 100%;
          background-color: rgba(#1b2c57, 0.2);

          .title { 
            width: 100%;
            height: 12%;
            position: relative;

            span {
              font-weight: bold; 
              color: #fff; 
              cursor: pointer; 
              position: absolute;
              bottom: 0;
              right: 20px;
            }
          }

          .chart {
            width: 100%;
            height: 88%;
            position: relative;
            padding: 0 !important;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    .title { 
      font-size: 10px !important;
    }
  }
</style>
